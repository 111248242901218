import React from 'react';
import { BrowserRouter as Router, Redirect, Route, Switch } from 'react-router-dom';
import { toast, ToastContainer } from 'react-toastify';
import { CloseButton, Fade } from '../components/common/Toast';
import loadable from '@loadable/component';
import AppContext from '../context/Context';

const LoginComponent = loadable(() => import('../components/login/Login'));
const LogoutComponent = loadable(() => import('../components/login/Logout'));
const SetPasswordComponent = loadable(() => import('../components/login/Set-Password'));
const ChanagePasswordComponent = loadable(() => import('../components/login/ChangePassword'));
const DashboardLayout = loadable(() => import('../layouts/DashboardLayout'));
const ForgotPassword =  loadable(()=>import('../components/login/Forgot-Password'));
// import ForgotPassword from '../components/login/Forgot-Password'

const Layout = () => {
  const {currentuser} = React.useContext(AppContext);
  if(currentuser){
    return (
      <Router fallback={<span />}>
      <Switch>
        <Route path="/login" component={LoginComponent} /> 
        <Route path="/logout" component={LogoutComponent} /> 
        <Route path="/change-password" component={ChanagePasswordComponent}/>
        <Route path="/forgot-password" exact component={ForgotPassword}/>
        <Route path="/set-password" exact component={ForgotPassword} />
        <Route component={DashboardLayout} />
      </Switch>
      <ToastContainer transition={Fade} closeButton={<CloseButton />} position={toast.POSITION.TOP_RIGHT} />
    </Router>
    );
  }else{
    return (
      <Router fallback={<span />}>
        <Switch>
          <Route path="/login" component={LoginComponent} />
          <Route path="/logout" component={LogoutComponent} /> 
          <Route path="/forgot-password" component={ForgotPassword}/>
          <Route path="/set-password" exact component={ForgotPassword} />
          <Route path="/registration/set-password" component={SetPasswordComponent}/>
          <Route path="/" component={LoginComponent} /> 
          <Route path="" component={LoginComponent} /> 
          <Redirect to="*" component={LoginComponent} /> 
        </Switch>
        <ToastContainer transition={Fade} closeButton={<CloseButton />} position={toast.POSITION.TOP_RIGHT} />
      </Router>
    );
  }
  
};

// const LoggedOutView = (props) => {
//   if (props.currentuser == null) {
//     return (
//       <Switch>
//         <Route exact path="/" component={LoginComponent} />
//         <Route exact path="/login" component={LoginComponent} />
//         <Route path="/registration/set-password" component={SetPasswordComponent}/>
//       </Switch>
//     );
//   }
//   return null;
// };
class Maincontent extends React.Component {
  render() { 
    return (
        <Layout />
    );
  }
}

export default Maincontent;
