import { createContext } from 'react';

const AppContext = createContext({
  isFluid: false, 
  isRTL: false,
  isDark: false,
  showBurgerMenu: false, // controls showing vertical nav on mobile
  currency: '$',
  isNavbarVerticalCollapsed: false,
  navbarStyle: 'card',
  currentuser: {},
  channelsList : [],
});

export const ClientContext = createContext({ client: {} });

export const CampaignContext = createContext({ campaign: {} });

export const AssessmentContext = createContext({ assessment: {} });

export const TrainingContext = createContext({ training: {} });

export default AppContext;
