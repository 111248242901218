import { SET_TEMPLATE_LIBRARY, TOGGLE_TEMPLATE_STATUS } from '../constants/actionTypes';

const initialState = {
  templateLibrary: {} // { [channel1]: { [category]: [ ...list ] }, [channel2]: { [category]: [ ...list ] }, }
};

export default (state = initialState, action) => {
  switch (action.type) {
    case SET_TEMPLATE_LIBRARY: {
      const { channel, category, data } = action.payload;
      return {
        ...state,
        templateLibrary: {
          ...state.templateLibrary,
          [channel]: { ...state.templateLibrary[channel], [category]: data }
        }
      };
    }

    case TOGGLE_TEMPLATE_STATUS: {
      const { templateId } = action.payload;

      const updatedTemplateLibary = { ...state.templateLibrary };

      for (const channel in updatedTemplateLibary) {
        updatedTemplateLibary[channel] = { ...updatedTemplateLibary[channel] };

        for (const category in updatedTemplateLibary[channel]) {
          updatedTemplateLibary[channel][category] = updatedTemplateLibary[channel][category].map(item => {
            if (item.id === templateId) {
              item.status = !item.status;
            }

            return { ...item };
          });
        }
      }

      return {
        ...state,
        templateLibrary: updatedTemplateLibary
      };
    }

    default:
      return state;
  }
};
