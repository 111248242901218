import { NEWASSEMENT_LOAD, NEWASSEMENT_UNLOAD, ASSEMENT_LOAD, ASSEMENT_UNLOAD } from '../constants/actionTypes';

export default (state = {}, action) => {
  switch (action.type) {
    case NEWASSEMENT_LOAD:
      return {
        ...state,
        token: action.token || null,
        appLoaded: true,
        api_data: action.payload ? action.payload.data : ''
      };
    case NEWASSEMENT_UNLOAD:
      return {
        ...state,
        token: action.token || null,
        appLoaded: true,
        api_data: null
      };
    case ASSEMENT_LOAD:
      return {
        ...state,
        token: action.token || null,
        appLoaded: true,
        assessment_data: action.payload ? action.payload.data : ''
      };
    case ASSEMENT_UNLOAD:
      return {
        ...state,
        token: action.token || null,
        appLoaded: true,
        assessment_data: null
      };
    default:
      return state;
  }
};
