import { SET_USERS,SET_USERS_INACTIVE,SET_USERS_ARCHIVE } from '../constants/actionTypes';

const initialState = {
  users: [],
  
};

export default (state = initialState, action) => {
  switch (action.type) {
    case SET_USERS: {
      return {
        ...state,
        users: action.payload
      }
      
    }
    case SET_USERS_INACTIVE:{
      return{
        ...state,
        users: action.payload
      }
    }
    case SET_USERS_ARCHIVE:{
      return{
        ...state,
        users: action.payload
      }
    }

    default:
      return state;
  }
};
