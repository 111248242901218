import React, { useState, useEffect, useCallback } from 'react';
import PropTypes from 'prop-types';
import AppContext from './context/Context';
import toggleStylesheet from './helpers/toggleStylesheet';
import { getItemFromStore, setItemToStore, themeColors, getItemFromSessionStore } from './helpers/utils';

import ErrorModal from 'components/common/ErrorModal';
import usePubSub from 'utils/usePubSub';
import usePrevious from 'utils/usePrevious';
import NotificationsHandler from './NotificationHandler';

const Main = ({ onDarkModeChange, ...props }) => {
  const [isFluid, setIsFluid] = useState(getItemFromStore('isFluid', false));
  const [isRTL, setIsRTL] = useState(getItemFromStore('isRTL', false));
  const [isDark, setIsDark] = useState(getItemFromStore('isDark', true));
  const previousIsDark = usePrevious(isDark);
  const [isNavbarVerticalCollapsed, setIsNavbarVerticalCollapsed] = useState(
    getItemFromStore('isNavbarVerticalCollapsed', false)
  );
  const [currentuser] = useState(getItemFromSessionStore('currentUser'));
  const [channelsList, setChannelsList] = React.useState([]);
  const [currency, setCurrency] = useState('$');
  const [showBurgerMenu, setShowBurgerMenu] = useState(false);
  const [isLoaded, setIsLoaded] = useState(false);
  const [isOpenSidePanel, setIsOpenSidePanel] = useState(false);
  const [showErrorModal, setShowErrorModal] = useState(false);
  const [navbarStyle, setNavbarStyle] = useState(getItemFromStore('navbarStyle', 'card'));
  const toggleModal = () => setIsOpenSidePanel(prevIsOpenSidePanel => !prevIsOpenSidePanel);

  const value = {
    isRTL,
    isDark,
    isFluid,
    setIsRTL,
    currency,
    setIsDark,
    setIsFluid,
    toggleModal,
    // setIsTopNav,
    navbarStyle,
    setCurrency,
    showBurgerMenu,
    setNavbarStyle,
    isOpenSidePanel,
    setShowBurgerMenu,
    setIsOpenSidePanel,
    isNavbarVerticalCollapsed,
    setIsNavbarVerticalCollapsed,
    currentuser,
    channelsList,
    setChannelsList
  };

  const setStylesheetMode = mode => {
    setIsLoaded(false);
    setItemToStore(mode, value[mode]);
    toggleStylesheet({ isRTL, isDark }, () => setIsLoaded(true));
  };

  useEffect(() => {
    let isActive = true;
    if (isActive) {
      setStylesheetMode('isFluid');
    }
    return () => {
      isActive = false;
    };
    // eslint-disable-next-line
  }, [isFluid]);

  useEffect(() => {
    let isActive = true;
    if (isActive) {
      let channels = [];
      if (currentuser?.channels?.paa_SMS) {
        channels.push('SMS');
      }
      if (currentuser?.channels?.paa_email) {
        channels.push('email');
      }
      if (currentuser?.channels?.paa_whatsapp) {
        channels.push('whatsApp');
      }
      setChannelsList(channels);
    }
    return () => {
      isActive = false;
    };
  }, [currentuser]);

  useEffect(() => {
    let isActive = true;
    if (isActive) {
      setStylesheetMode('isRTL');
    }
    return () => {
      isActive = false;
    };
    // eslint-disable-next-line
  }, [isRTL]);

  useEffect(() => {
    let isActive = true;
    if (isActive) {
      setStylesheetMode('isDark');
    }
    return () => {
      isActive = false;
    };
    // eslint-disable-next-line
  }, [isDark]);

  useEffect(() => {
    let isActive = true;
    if (isActive) {
      setItemToStore('isNavbarVerticalCollapsed', isNavbarVerticalCollapsed);
    }
    return () => {
      isActive = false;
    };
    // eslint-disable-next-line
  }, [isNavbarVerticalCollapsed]);

  useEffect(() => {
    let isActive = true;
    if (isActive) {
      setItemToStore('navbarStyle', 'card');
    }
    return () => {
      isActive = false;
    };
    // eslint-disable-next-line
  }, [navbarStyle]);

  useEffect(() => {
    if (previousIsDark !== isDark) {
      onDarkModeChange(isDark);
    }
  }, [previousIsDark, isDark, onDarkModeChange]);

  usePubSub(
    'fatal_error',
    useCallback(() => {
      setShowErrorModal(true);
    }, [])
  );

  if (!isLoaded) {
    toggleStylesheet({ isRTL, isDark }, () => setIsLoaded(true));

    return (
      <div
        style={{
          position: 'fixed',
          top: 0,
          right: 0,
          bottom: 0,
          left: 0,
          backgroundColor: isDark ? themeColors.dark : themeColors.light
        }}
      />
    );
  }

  return (
    <AppContext.Provider value={value}>
      {props.children}
      {showErrorModal ? <ErrorModal /> : null}
      <NotificationsHandler currentuser={currentuser} />
    </AppContext.Provider>
  );
};

Main.propTypes = { children: PropTypes.node };

export default Main;
