import {
  LOGIN,
  LOGIN_PAGE_UNLOADED,
  ASYNC_START,
  LOGIN_CODE_GENERATE,
  CLIENT_LOGIN,
  RESET_LOGIN_PAGE
} from '../constants/actionTypes';

export default (state = {}, action) => {
  switch (action.type) {
    case LOGIN:
      return {
        ...state,
        redirectTo: action.error ? null : '/',
        token: action.error ? null : action.payload.user.token,
        currentUser: action.error ? null : action.payload.user
      };
    case LOGIN_PAGE_UNLOADED:
      return { ...state, viewChangeCounter: state.viewChangeCounter + 1 };
    case ASYNC_START:
      if (action.subtype === LOGIN) {
        return { ...state, inProgress: true };
      }
      break;
    case LOGIN_CODE_GENERATE:
      if (action.payload.error === 1) {
        return { ...state, error: action.payload.error, msg: action.payload.msg };
      } else {
        return { ...state, error: action.payload.error, msg: action.payload.msg, action: action.payload.action };
      }
    case CLIENT_LOGIN:
      if (action.payload.error === 1) {
        return { ...state, error: action.payload.error, msg: action.payload.msg };
      } else {
        return {
          ...state,
          error: action.payload.error,
          msg: action.payload.msg,
          token: action.payload.user.token,
          currentUser: action.payload.user
        };
      }
     
    case RESET_LOGIN_PAGE:
      return { ...state, error: undefined, action: undefined,msg :'' };
    default:
      return state;
  }
  return state;
};
