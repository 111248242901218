import { PHISHEDUSER_LOAD, PHISHEDUSER_UNLOAD, PHISHINGDASHBOARD_LOAD, PHISHINGDASHBOARD_UNLOAD,TRAINING_SCHEDULE_LOAD,TRAINING_SCHEDULE_UNLOAD,BUCKET_LOAD,BUCKET_UNLOAD} from '../constants/actionTypes';

export default (state = {}, action) => {
  switch (action.type) {
    case PHISHINGDASHBOARD_LOAD:
      return {
        ...state,
        token: action.token || null,
        appLoaded: true,
        phishingdashboard_data: action.payload ? action.payload.data : ''
      };
    case PHISHINGDASHBOARD_UNLOAD:
      return {
        ...state,
        token: action.token || null,
        appLoaded: true,
        phishingdashboard_data: null
      };
    case PHISHEDUSER_LOAD:
      return {
        ...state,
        token: action.token || null,
        appLoaded: true,
        phisheduser_data: action.payload ? action.payload.data : ''
      };
    case PHISHEDUSER_UNLOAD:
      return {
        ...state,
        token: action.token || null,
        appLoaded: true,
        phisheduser_data: null
      };
    case TRAINING_SCHEDULE_LOAD:
        return {
          ...state,
          token: action.token || null,
          appLoaded: true,
          traingin_schedule_data: action.payload ? action.payload.data : ''
        };
    case TRAINING_SCHEDULE_UNLOAD:
          return {
            ...state,
            token: action.token || null,
            appLoaded: true,
            traingin_schedule_data: null
          };
    case BUCKET_LOAD:
            return {
              ...state,
              token: action.token || null,
              appLoaded: true,
              bucket_details: action.payload ? action.payload.data : ''
            };
    case BUCKET_UNLOAD:
              return {
                ...state,
                token: action.token || null,
                appLoaded: true,
                bucket_details: null
              };
        
          default:
      return state;
  }
};
