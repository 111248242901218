export const APP_LOAD = 'APP_LOAD';
export const LOGIN = 'LOGIN';
export const LOGIN_CODE_GENERATE = 'LOGIN_CODE_GENERATE';
export const CLIENT_LOGIN = 'CLIENT_LOGIN';
export const LOGIN_PAGE_UNLOADED = 'LOGIN_PAGE_UNLOADED';
export const LOGOUT = 'LOGOUT';
export const RESET_LOGIN_PAGE = 'RESET_LOGIN_PAGE';
export const HOME_PAGE_LOADED = 'HOME_PAGE_LOADED';
export const HOME_PAGE_UNLOADED = 'HOME_PAGE_UNLOADED';
export const ASYNC_START = 'ASYNC_START';
export const ASYNC_END = 'ASYNC_END';
export const REDIRECT = 'REDIRECT';
export const CHANGE_SIDEBAR_WIDTH = 'CHANGE_SIDEBAR_WIDTH';
export const PH_SP = 'PH_SP';
export const PHISHINGDASHBOARD_LOAD = 'PHISHINGDASHBOARD_LOAD';
export const PHISHINGDASHBOARD_UNLOAD = 'PHISHINGDASHBOARD_UNLOAD';
export const NEWASSEMENT_LOAD = 'NEWASSEMENT_LOAD';
export const NEWASSEMENT_UNLOAD = 'NEWASSEMENT_UNLOAD';
export const ASSEMENT_LOAD = 'ASSEMENT_LOAD';
export const ASSEMENT_UNLOAD = 'ASSEMENT_UNLOAD';
export const PHISHEDUSER_LOAD = 'PHISHEDUSER_LOAD';
export const PHISHEDUSER_UNLOAD = 'PHISHEDUSER_UNLOAD';
export const TRAINING_SCHEDULE_LOAD = 'TRAINING_SCHEDULE_LOAD';
export const TRAINING_SCHEDULE_UNLOAD = 'TRAINING_SCHEDULE_UNLOAD';
export const BUCKET_LOAD = 'BUCKET_LOAD';
export const BUCKET_UNLOAD = 'BUCKET_UNLOAD';

// Trainings
export const SET_VIDEOS = 'SET_VIDEOS';
export const SET_POSTERS = 'SET_POSTERS';
export const SET_POLICIES = 'SET_POLICIES';
export const CLEAR_ALL_LIBRARY = 'CLEAR_ALL_LIBRARY';

// Users
export const SET_USERS = 'SET_USERS';
export const SET_USERS_INACTIVE = 'SET_USERS_INACTIVE'
export const SET_USERS_ARCHIVE = 'SET_USERS_ARCHIVE'


// Groups
export const SET_GROUPS = 'SET_GROUPS';

//Groups Archive-user
export const SET_ARCHIVE_USERS = 'SET_ARCHIVE_USERS';

// Template Library
export const SET_TEMPLATE_LIBRARY = 'SET_TEMPLATE_LIBRARY';
export const TOGGLE_TEMPLATE_STATUS = 'TOGGLE_TEMPLATE_STATUS';

// Landing Pages Library
export const SET_LANDING_PAGES_LIBRARY = 'SET_LANDING_PAGES_LIBRARY';
export const INVALIDATE_LANDING_PAGES_LIBRARY = 'INVALIDATE_LANDING_PAGES_LIBRARY';

// JIT Pages Library
export const SET_JIT_PAGES_LIBRARY = 'SET_JIT_PAGES_LIBRARY';
export const INVALIDATE_JIT_PAGES_LIBRARY ='INVALIDATE_JIT_PAGES_LIBRARY';

// Administration > Manage
export const SET_ADMINISTRATORS = 'SET_ADMINISTRATORS';
export const ADD_ADMINISTRATOR = 'ADD_ADMINISTRATOR';

// Phishing Campaigns
export const SET_CAMPAIGNS_LIST = 'SET_CAMPAIGNS_LIST';
export const ADD_CAMPAIGN_TO_LIST = 'ADD_CAMPAIGN_TO_LIST';
