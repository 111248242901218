import { SET_ARCHIVE_USERS, SET_GROUPS } from '../constants/actionTypes';

const initialState = {
  groups: [],
  groupsArchiveUsers:[]
  
};
export default (state = initialState, action) => {
  switch (action.type) {
    case SET_GROUPS: {
      return {
        ...state,
        groups: action.payload
      };
    }
    case SET_ARCHIVE_USERS:{
      return{
        ...state,
        groupsArchiveUsers: action.payload
      }
    }

    default:
      return state;
  }
};
