import React from 'react';
import { BrowserRouter as Router } from 'react-router-dom';
import { APP_LOAD, REDIRECT, LOGOUT } from './constants/actionTypes';
import 'react-toastify/dist/ReactToastify.min.css';
import 'react-datetime/css/react-datetime.css';
import 'react-image-lightbox/style.css';
import { push } from 'react-router-redux';
import { store } from './store';
import agent from './agent';
import agent2 from './utils/agent'; // agent2 in utils/ will replace agent. This is done to allow absolute path import in components.
import { connect } from 'react-redux';
import Maincontent from './layouts/Layout';
import AutoLogout from './AutoLogout';

import './styles/index.scss';

const mapStateToProps = state => {
  return {
    appLoaded: state.common.appLoaded,
    appName: state.common.appName,
    currentUser: state.common.currentUser,
    redirectTo: state.common.redirectTo
  };
};

const mapDispatchToProps = dispatch => ({
  onClickLogout: () => dispatch({ type: LOGOUT }),
  onLoad: (payload, token) => dispatch({ type: APP_LOAD, payload, token, skipTracking: true }),
  onRedirect: () => dispatch({ type: REDIRECT })
});
class App extends React.Component {
  componentWillReceiveProps(nextProps) {
    // let chk = (this.props.currentUser && this.props.currentUser.action)?this.props.currentUser.action:'';
    //if(chk=='login'){
    //   window.location.href='/';
    //}

    if (nextProps.redirectTo) {
      store.dispatch(push(nextProps.redirectTo));
      this.props.onRedirect();
    }
  }

  componentWillMount() {
    const token = window.sessionStorage.getItem('jwt');
    if (token) {
      agent.setToken(token);
      agent2.setToken(token);
    }

    //  this.props.onLoad(token ? agent.Auth.current() : null, token);
  }

  render() {
    return (
      <Router basename={process.env.PUBLIC_URL}>
        <Maincontent currentUser={this.props.currentUser} />
        <AutoLogout />
      </Router>
    );
  }
}

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(App);
