import { SET_CAMPAIGNS_LIST, ADD_CAMPAIGN_TO_LIST } from '../constants/actionTypes';

const initialState = {
  campaignsList: []
};

export default (state = initialState, action) => {
  switch (action.type) {
    case SET_CAMPAIGNS_LIST: {
      const existing = {};
      const filtered = [];

      // Filter out duplicates
      // if (!action.payload || !Array.isArray(action.payload) || !action.payload.length) return state;

      if (!action.payload || !Array.isArray(action.payload)) return state;
      if (action.payload.length === 0) return { ...state, campaignsList: [] };
      
      for (const item of action.payload) {
        if (existing[item.id]) continue;

        existing[item.id] = true;
        filtered.push(item);
      }
      return {
        ...state,
        campaignsList: filtered
      };
    }
    case ADD_CAMPAIGN_TO_LIST: {
      const existing = {};
      const filtered = [];

      // Filter out duplicates
      const newList = [action.payload, ...state.campaignsList];

      for (const item of newList) {
        if (existing[item.id]) continue;

        existing[item.id] = true;
        filtered.push(item);
      }

      return {
        ...state,
        campaignsList: filtered
      };
    }
    default:
      return state;
  }
};
