import { SET_LANDING_PAGES_LIBRARY, INVALIDATE_LANDING_PAGES_LIBRARY } from '../constants/actionTypes';

const initialState = {
  landingPagesLibrary: {} // { [channel1]: { [category]: [ ...list ] }, [channel2]: { [category]: [ ...list ] }, }
};

export default (state = initialState, action) => {
  switch (action.type) {
    case SET_LANDING_PAGES_LIBRARY: {
      const { channel, category, data } = action.payload;
      return {
        ...state,
        landingPagesLibrary: {
          ...state.landingPagesLibrary,
          [channel]: { ...state.landingPagesLibrary[channel], [category]: data }
        }
      };
    }

    case INVALIDATE_LANDING_PAGES_LIBRARY: {
      return { ...state, landingPagesLibrary: {} };
    }

    default:
      return state;
  }
};
