import React, { useRef, useCallback } from 'react';

import _ from 'lodash';

import usePubSub from 'utils/usePubSub';
import Trainings from './Handlers/Trainings';
import PhishingCampaigns from './Handlers/PhishingCampaigns';
import PDFReport from './Handlers/PDFReport';
// import Socket from './Socket';
import FCM from './FCM';
import NotifyNotification from './Handlers/NotifyNotification';
import BulkReminder from './Handlers/BulkReminder';

function NotificationsHandler({ currentuser }) {
  const { publish } = usePubSub();
  const currentuserRef = useRef(currentuser);
  currentuserRef.current = currentuser;
  // We maintain a list of notifications which have been shown.
  // We use this to prevent showing a notification twice as may receive
  // the same notification twice, one from FCM and one from socket.
  const handledNotificationsListRef = useRef([]);

  

  const handleNotificationPayload = useCallback(_data => {
    // if (!_data)  return;
    if (!_data)return;
    

    if (!_data.type) return;
    
  
    if (
      (!_data.assigned_by && !_data.sent_by) ||
      !(_data.assigned_by || _data.sent_by).user_id ||
      !(_data.assigned_by || _data.sent_by).email  
    ) return

    if (
      currentuserRef.current.user_id !== (_data.assigned_by || _data.sent_by).user_id,
      currentuserRef.current.email !== (_data.assigned_by || _data.sent_by).email
    ) return;

    const data = {
      type: _data.type,
      trainingName: _data.trainingName,
      campaignName: _data.campaignName,
      campaignObj: _data.campaignObj,
      msg: _data.msg,
      status: _data.status === 'delivered' ? 'SUCCESS': _data.status === 'not-delivered' ? 'FAILED' : _data.status === 'sent' ? 'SUCCESS': _data.status === 'bulk' ? 'SUCCESS': _data.status
    };
    let shouldIgnoreNotification = false;
    for (const notification of handledNotificationsListRef.current) {
      const { notificationTime, ...existingNotificationData } = notification;

      // Ignore notification payload which has already been shown if it was shown less than 15 seconds ago.
      if (_.isEqual(existingNotificationData, data) && Date.now() - notification.notificationTime < 15 * 1000) {
        shouldIgnoreNotification = true;

        break;
      }
    }

    if (!shouldIgnoreNotification) {

      publish(data.type, { ...data, timestamp: new Date() });

      if (handledNotificationsListRef.current.length > 50) {

        handledNotificationsListRef.current.shift();
      }

      handledNotificationsListRef.current.push({ notificationTime: Date.now(), ...data });
    }
  }, []);

  return (
    <>
      {/* <FCM currentuser={currentuser} onNotification={handleNotificationPayload} /> */}
      {/* <Socket currentuser={currentuser} onNotification={handleNotificationPayload} /> */}

      {/* <Trainings />
      <PhishingCampaigns />
      <PDFReport />
      <NotifyNotification/>
      <BulkReminder/> */}
    </>
  );
}

export default NotificationsHandler;
