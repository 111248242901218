import { SET_ADMINISTRATORS, ADD_ADMINISTRATOR } from '../constants/actionTypes';

const initialState = {
  administrators: []
};
export default (state = initialState, action) => {
  switch (action.type) {
    case SET_ADMINISTRATORS: {
      return {
        ...state,
        administrators: action.payload
      };
    }
    case ADD_ADMINISTRATOR: {
      return {
        ...state,
        administrators: [action.payload, ...state.administrators]
      };
    }
    default:
      return state;
  }
};
