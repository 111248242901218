import { SET_VIDEOS, SET_POSTERS, SET_POLICIES, CLEAR_ALL_LIBRARY } from '../constants/actionTypes';

const initialState = {
  videos: {
    lists: {}, // { [languageCode]: [...list_of_videos], ... }
    page: 1
  },
  posters: {
    lists: {}, // { [languageCode]: [...list_of_posters], ... }
    page: 1
  },
  policies: {
    lists: {}, // { [languageCode]: [...list_of_policies], ... }
    page: 1
  }
};

export default (state = initialState, action) => {
  switch (action.type) {
    case SET_VIDEOS: {
      const { language, data } = action.payload;

      return {
        ...state,
        videos: {
          ...state.videos,
          lists: { ...state.videos.lists, [language]: [...data] }
        }
      };
    }

    case SET_POSTERS: {
      const { language, data } = action.payload;
      return {
        ...state,
        posters: {
          ...state.posters,
          lists: { ...state.posters.lists, [language]: [...data] }
        }
      };
    }

    case SET_POLICIES: {
      const { language, data } = action.payload;
      return {
        ...state,
        policies: {
          ...state.policies,
          lists: { ...state.policies.lists, [language]: [...data] }
        }
      };
    }

    case CLEAR_ALL_LIBRARY: {
      return { ...initialState };
    }
    default:
      return state;
  }
};
