import { combineReducers } from 'redux';
import auth from './reducers/auth';
import common from './reducers/common';
import cspa from './reducers/cspa';
import paa from './reducers/paa';
import training from './reducers/training';
import phishing from './reducers/phishing';
import users from './reducers/users';
import groups from './reducers/groups';
import templateLibrary from './reducers/template-library';
import landingPagesLibrary from './reducers/landing-pages-library';
import jitPagesLibrary from 'reducers/jit-pages-library';
import admin from './reducers/admin';
//import client from './reducers/client';
import { connectRouter } from 'connected-react-router';
import { history } from './history';

export default combineReducers({
  auth,
  common,
  cspa,
  paa,
  training,
  phishing,
  users,
  groups,
  templateLibrary,
  landingPagesLibrary,
  jitPagesLibrary,
  admin,
  //client,
  router: connectRouter(history)
});
