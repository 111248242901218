import superagentPromise from 'superagent-promise';
import _superagent from 'superagent';
import axios from 'axios';

const superagent = superagentPromise(_superagent, global.Promise);

// const API_ROOT_LOCAL = 'https://dev-api.cyberq.seclogic.io:8000/api';
//prod url
// const API_ROOT_LOCAL = 'https://api.cyberqoro.seclogic.io/api';

// qa url link
// const API_ROOT_LOCAL = 'http://api.cyberqoro.com:8000/api';
// const API_ROOT_LOCAL = 'https://hf-api.cyberq.seclogic.io/api';

// REACT_APP_API_ROOT_LOCAL=https://dev-api.cyberq.seclogic.io:8000/api;

// const API_ROOT_LOCAL = 'https://qa.cyberq.seclogic.io:8000/api/';
 const API_ROOT_LOCAL = process.env.REACT_APP_API_ROOT_LOCAL || 'http://127.0.0.1:8000/api';
//  const API_ROOT_LOCAL = process.env.REACT_APP_API_ROOT_LOCAL || 'http://127.0.0.1:8000/api';
  // const API_ROOT_LOCAL = 'http://127.0.0.1:8000/api';
//const API_ROOT = API_ROOT_LOCAL;
// const API_ROOT = API_ROOT_LIVE
// const API_ROOT_LIVE = 'https://qa.cyberq.seclogic.io:8000/api';
 const API_ROOT = API_ROOT_LOCAL;

//const encode = encodeURIComponent;
const responseBody = res => res.body;

let token = null;
const tokenPlugin = req => {
  if (token) {
    req.set('authorization', `Token ${token}`);
  }
};

const requests = {
  del: url =>
    superagent
      .del(`${API_ROOT}${url}`)
      .use(tokenPlugin)
      .then(responseBody)
      .catch(err =>
        err.response
          ? { error: true, error_msg: err.response.message, status: err.response.status }
          : { error: true, error_msg: err.message || 'Request failed', status: null }
      ),
  get: url =>
    superagent
      .get(`${API_ROOT}${url}`)
      .use(tokenPlugin)
      .then(responseBody)
      .catch(err =>
        err.response
          ? { error: true, error_msg: err.response.message, status: err.response.status }
          : { error: true, error_msg: err.message || 'Request failed', status: null }
      ),
  put: (url, body) =>
    superagent
      .put(`${API_ROOT}${url}`, body)
      .use(tokenPlugin)
      .then(responseBody)
      .catch(err =>
        err.response
          ? { error: true, error_msg: err.response.message, status: err.response.status }
          : { error: true, error_msg: err.message || 'Request failed', status: null }
      ),
  post: (url, body) =>
    superagent
      .post(`${API_ROOT}${url}`, body)
      .use(tokenPlugin)
      .then(responseBody)
      .catch(err =>
        err.response
          ? { error: true, error_msg: err.response.message, status: err.response.status }
          : { error: true, error_msg: err.message || 'Request failed', status: null }
      )
};

const Auth = {
  // current: () => requests.get('/user'),
  login: (email, password) => requests.post('/users/login', { user: { email, password } }),
  clientLoginToken: (email, password) => requests.post('/login', { user: { email, password } }),
  clientLogin: (email, password, verifycode) => requests.post('/login', { user: { email, password, verifycode } }),
  forgotPassword: email => requests.post('/ForgotPassword', { data: { email } }),
  setPassword: (query, password, c_password) =>
    requests.put(`/ForgotPassword`, { data: { query, password, c_password } })
  // save: user => requests.put('/user', { user })
};

const Cspa = {
  newassessment: (action = '') => requests.post('/cloudsecurity/scoutsuite/new_assessment', { data: { action } }),
  assessmentdetails: (action = '') =>
    requests.post('/cloudsecurity/scoutsuite/assessment_details', { data: { action } }),
  schedule_scan: (data = '') => requests.post('/cloudsecurity/scoutsuite/schedule_scan', { data }),
  accounts: (action = '') => requests.post('/cloudsecurity/scoutsuite/accounts', { data: { action } }),
  completed_assessment: (code = '') =>
    requests.post('/cloudsecurity/scoutsuite/completed_assessment', { data: { code } })
};
const limit = (count, p) => `limit=${count}&offset=${p ? p * count : 0}`;
const omitSlugClient = client => Object.assign({}, client, { slug: undefined });
const Clients = {
  all: page => requests.get(`/clients?${limit(10, page)}`),
  del: slug => requests.del(`/clients/${slug}`),
  chunk: page => requests.get(`/clients?${limit(10, page)}`),
  get: slug => requests.get(`/clients/${slug}`),
  update: client => requests.put(`/clients/${client.slug}`, { client: omitSlugClient(client) }),
  create: client => requests.post('/clients', { client }),
  get_users: data => requests.post('/getUserList/', {data:data}),
  // get_users: (data = {}) => requests.post('/getUserList/', data),
  update_user: (data = {}) => requests.post('/UpdateUser/', {data: data}),
  create_user: (data = {}) => requests.post('/AddNewUser/', {data}),
  archive_user: (data = {}) => requests.post('/ArchiveUser/', {data : data}),
  resend_email: (data = {}) => requests.post('/ResendEmailNotification/', {data}),
  ldap_configuration: formdata => requests.post('/peopleawareness/usersandgroupsactions', { data: formdata }),

  // ldap_configuration: (data = {}) => requests.post('/peopleawareness/usersandgroupsactions', data)
};

const Phishing = {
  get_sp: () => requests.get(`/peopleawareness/sendingprofile`),
  get_template: () => requests.get(`/peopleawareness/templates`),
  get_landing: () => requests.get(`/peopleawareness/landingpages`),
  get_phishing_list: data => requests.post(`/peopleawareness/removecampaign`, { data: data }),

  // get_usergroups: formdata => requests.get(`/peopleawareness/removecampaign`, { data: { formdata } }),  
  get_clients: () => requests.get(`/getClients`),
  create_rp: (formdata, action) => requests.post('/peopleawareness/reportingprofile', { data: { formdata, action } }),
  create_sp: (formdata, action, currentuser) =>
    requests.post('/peopleawareness/sendingprofile', { data: { formdata, action, currentuser } }),
  phised_users: data => requests.post(`/peopleawareness/phishedusers`, { data: { data } }),
  download_report: (formdata, action, channel) =>
    requests.post('/peopleawareness/reports', { data: { formdata, action, channel } }),
  phished_userwise: data => requests.post(`/peopleawareness/phisheddatauserswise`, { data: { data } }),
  phished_groupwise: data => requests.post(`/peopleawareness/phisheddatagroupswise`, { data: { data } }),
  campaign_by_id: (formdata, action, channel, currentuser) =>
    requests.post('/peopleawareness/campaigns', { data: { formdata, action, channel, currentuser } }),

  create_group: formdata => requests.post('/peopleawareness/usersandgroups', { data: formdata }),
  create_groups: formdata => requests.post('/peopleawareness/dataandgroupsms', { data: formdata }),
  create_voices: formdata => requests.post('/peopleawareness/dataandgroupvoice', { data: formdata }),

  group_actions: formdata => requests.post('/peopleawareness/usersandgroupsactions', { data: formdata }),
  create_campaign: data => requests.post('/peopleawareness/campaigns', { data: data }),
  discard_campaign: data => requests.post('/peopleawareness/discardCampaign', {data: data}),
  discard_campaign_sms: data => requests.post('/peopleawareness/discardCampaign', {data: data}),
  discard_campaign_voice: data => requests.post('/peopleawareness/discardCampaignvoice', {data: data}),
  create_campaign_sms: data => requests.post('/peopleawareness/datafetchforsms', { data: data }),
  create_campaign_voice: data => requests.post('/peopleawareness/voicecampaigns', { data: data }),
  report_overwrite: formdata => requests.post('/peopleawareness/report_overwrite', { data: formdata }),



  template_validation: currentuser => requests.post('/peopleawareness/checktemplatevalidation', { data: currentuser }),
  campaign_actions: data => requests.post('/peopleawareness/campaignsactions', { data: data }),
  smishing_camaign :data => requests.post('/peopleawareness/smscampaignsactions', {data:data}),
  voice_camaign :data => requests.post('/peopleawareness/voicecampaignsactions', {data:data}),

  last_15_campaigns: formdata => requests.post(`/peopleawareness/last15campaigndata`, { data: formdata }),
  // phished_user_maindashboard: data => requests.post(`/peopleawareness/phisheddatamaindashboard`, { data: { data } }),
  phished_user_maindashboard: currentuser => requests.post(`/peopleawareness/phisheddatamaindashboard`, { data: { currentuser } }),
  phished_user_maindashboard_sms: currentuser => requests.post(`/peopleawareness/phishedashboardsms`, { data: { currentuser } }),
  phished_user_maindashboard_voice: currentuser => requests.post(`/peopleawareness/phishedashboardvoice`, { data: { currentuser } }),

  phished_risk_posture_group_maindashboard: currentuser =>
    requests.post(`/peopleawareness/phishedriskposturedatamaindashboard`, { data: { currentuser } }),
    phished_risk_posture_group_maindashboard_voice: currentuser =>
  requests.post(`/peopleawareness/phishedriskposturemaindashboardvoice`, { data: { currentuser } }),
    phished_risk_posture_group_maindashboard_sms: currentuser =>
    requests.post(`/peopleawareness/phishedriskposturemaindashboardsms`, { data: { currentuser } }),
  phished_users_last12months: currentuser => requests.post(`/peopleawareness/last12monthsphishedusers`, { data: { currentuser } }),
  // phished_users_last12months: data => requests.post(`/peopleawareness/last12monthsphishedusers`, { data: { data } }),
  phished_users_last12monthssms: currentuser => requests.post(`/peopleawareness/PhishedUsersLast12MonthsSms`, { data: { currentuser } }),
  phished_users_last12monthsvoice: currentuser => requests.post(`/peopleawareness/last12monthsvishedusersvoice`, { data: { currentuser } }),
  all_user_report:formdata => requests.post(`/peopleawareness/ConsolidateReportForChannels`, { data: formdata }),

  phished_groups_last12months: currentuser => requests.post(`/peopleawareness/last12monthsphishgroups`, { data: { currentuser } }),
  phished_groups_last12months_sms: currentuser => requests.post(`/peopleawareness/last12monthsgroupsSms`, { data: { currentuser } }),
  phished_groups_last12months_voice: currentuser => requests.post(`/peopleawareness/last12monthsvishgroupsvoice`, { data: { currentuser } }),

  
  userwise_last12months: formdata => requests.post(`/peopleawareness/last12monthsuserwise`, { data: formdata }),
  userwise_last12months_voice: formdata => requests.post(`/peopleawareness/lastuserwisevoice`, { data: formdata }),
  userwise_last12months_sms: currentuser => requests.post(`/peopleawareness/lastuserwisesms`, { data: currentuser }),
  groupwise_last12months: currentuser => requests.post(`/peopleawareness/last12monthsgroupwise`, { data: currentuser }),
  groupwise_last12months_sms: currentuser => requests.post(`/peopleawareness/lastgroupwisesms`, { data: currentuser }),
  groupwise_last12months_voice: formdata => requests.post(`/peopleawareness/lastgroupwisevoice`, { data: formdata }),

  template_data: formdata => requests.post('/peopleawareness/templates', { data: formdata }),
  template_data_sms: formdata => requests.post('/peopleawareness/smstemplates', { data: formdata }),
  template_data_voice: formdata => requests.post('/peopleawareness/temvoice', { data: formdata }),

  template_actions: formdata => requests.post('/peopleawareness/templatesactions', { data: formdata }),
  landing_data: formdata => requests.post('/peopleawareness/landingpages', { data: formdata }),
  landing_datasms: formdata => requests.post('/peopleawareness/landingsms', { data: formdata }),
  jit_data: formdata => requests.post('/peopleawareness/jitimages',{data: formdata}),
  report_data: formdata => requests.post(`/peopleawareness/reportdata`, { data: formdata }),
  report_data_sms: formdata => requests.post(`/peopleawareness/smsreport`, { data: formdata }),
  report_data_voice: formdata => requests.post(`/peopleawareness/voicereport`, { data: formdata }),
  status_overwrite: formdata => requests.post(`/peopleawareness/status_overwrite`, { data: formdata }),
  registred_reminder_mail: formdata => requests.post(`/SetPassword/`, {data: formdata}),
  notify_users: formdata => requests.post('/peopleawareness/usersandgroups', { data: formdata }),
  // update: client =>
  //   requests.put(`/clients/${client.slug}`, { client: omitSlugClient(client) }),
  // create: client =>
  //   requests.post('/clients', { client })
};
const ClientDashboard = {
  get_dashboard: org => requests.get(`/dashboard?org=` + org),
  create_organization: formdata => requests.post('/registration', { data: formdata }),
  activate_user: formdata => requests.post('/activate', { data: formdata }),
  chanage_password: formdata => requests.post('/changepassword', { data: formdata }),
  client_login: formdata => requests.post('/login', { data: formdata }),
  partner_login: api_key => requests.post('/partner/login', { data: { api_key: api_key } }),
  invite_user: formdata => requests.post('/ResendEmailNotification', { data: formdata })
};

const Support = {
  get_create_issue_meta: data => requests.post(`/support/getcreateissuemeta`, data),
  creat_issue: formdata => requests.post('/support/creatIssue', formdata),
  upload_file: file => requests.post('/support/uploadfile', file),
  delete_file: (data, fileId) => requests.del(`/support/deletefile/${fileId}`),
  list_issue: data => requests.post('/support/listissue', data),
  create_feedback: data => requests.post('/support/createfeedback', data),
  get_feedback: data => requests.post('/support/getfeedback', data),
  update_issue: (data, issueId) => requests.post(`/support/updateIssue/${issueId}`, data),
  get_issue_history: data => requests.post('/support/getIssueHistory', data),
  get_issue_by_id: id => requests.get(`/support/getIssueById/${id}`),
  get_issue_status_preview: data => requests.post(`/support/getIssueStatus`, data)
};

const Training = {
  list_bucket: formdata => requests.post('/training/bucket/', { data: formdata }),
  licence_details: formdata => requests.post('/Licence_Details/', { data: formdata }),
  bucket_details: formdata => requests.post('/training/bucket/', { data: formdata }),

  assign_training: formdata => requests.post('/training/manage/', { data: formdata }),
  company_stats: formdata => requests.post('/training/manage/', { data: formdata }),
  group_stats: formdata => requests.post('/training/manage/', { data: formdata }),
  dashboard_stats: formdata => requests.post('/training/manage/', { data: formdata }),
  list_group: formdata => requests.post('/training/manage/', { data: formdata }),
  create_group: formdata => requests.post('/training/usersandgroups/', { data: formdata }),
  create_groups: formdata => requests.post('/peopleawareness/dataandgroupsms/', { data: formdata }),


  user_ugstats: formdata => requests.post('/training/manage/', { data: formdata }),
  group_ugstats: formdata => requests.post('/training/manage/', { data: formdata }),
  user_stat: formdata => requests.post('/training/manage/', { data: formdata }),
  group_stat: formdata => requests.post('/training/manage/', { data: formdata }),
  manual_reminder: formdata => requests.post('/training/manual-reminder/', {data:formdata}),
  user_timeline_ugstats: formdata => requests.post('/training/manage/', { data: formdata }),
  group_timeline_ugstats: formdata => requests.post('/training/manage/', { data: formdata }),
  user_status : formdata => requests.post('/training/employee/status',{data: formdata}),
  get_training_users: formdata => requests.post('/training/getTrainingUsers/', { data: formdata }),
  group_info: (cyberq_groupid, currentuser) =>
    requests.post('/training/manage/', {
      data: { action: 'group_info', cyberq_groupid: cyberq_groupid, currentuser: currentuser }
    }),
  user_info: (cyberq_groupid, currentuser) =>
    requests.post('/training/manage/', {
      data: { action: 'user_info', cyberq_userid: cyberq_groupid, currentuser: currentuser }
    }),
  fetch_library: ({ language, trainingType, currentuser }) =>
    requests.post('/training/ClientActivity/', {
      data: { action: 'fetch', currentuser, formdata: { lang: language, trainingType } }
    }),
  fetch_one: ({ language, trainingType, id, currentuser }) =>
    requests.post('/training/ClientActivity/', {
      data: { action: 'fetch_one', currentuser, formdata: { lang: language, trainingType, id } }
    }),
  /**
   *
   * @param {Object} param0
   * @param {string} param0.contentType videos|posters|policies
   * @returns
   */
  fetch_assigned: ({ contentType, currentuser, language }) =>
    requests.post('/training/bucket/', {
      data: { action: 'get_assigned_bucket_list', currentuser, formdata: { contentType, language } }
    }),

  fetch_assigned_users_for_bucket: ({ bucketId, currentuser }) =>
    requests.post('/training/bucket/', {
      data: { action: 'get_assigned_users_by_bucket_id', currentuser, formdata: { bucketId } }
    }),

  /**
   *
   * @param {Object} param0
   * @param {string} param0.filename The name of the file, e.g. file.png
   * @param {string} param0.thumbnailFilename The name of the thumbnail file, e.g. file.png
   * @param {Object} param0.currentuser
   * @returns
   */
  create_signed_url: ({ contentType, includeThumb = false, filename, thumbnailFilename, currentuser }) =>
    requests.post('/training/preSignedUrl/', {
      data: {
        formdata: {
          contentType,
          includeThumb,
          filename,
          ...(includeThumb && { thumbfileName: thumbnailFilename })
        },
        currentuser
      }
    }),
  upload_file_to_signed_url: ({ signedUrl, file, fileType }) =>
    axios.put(signedUrl, file, {
      headers: {
        'Content-Type': fileType || file.type || 'image/jpeg'
      }
    }),
  fetch_file_from_url: ({ fileUrl, currentuser }) =>
    axios.post(
      `${API_ROOT}/training/getFile/`,
      {
        formdata: {
          fileLink: fileUrl
        }
      },
      {
        headers: {
          authorization: `Token ${token}`
        },
        responseType: 'blob'
      }
    ),
  /**
   *
   * @param {Object} param0
   * @param {string} param0.contentType poster|policy
   * @param {string} param0.contentTypeSort PS|PL
   * @param {string} param0.thumbnailUrl
   * @param {string} param0.fileUrl
   * @param {string} param0.fileDefaultName Name of the file, eg. 'file.png'
   * @param {string} param0.lang Language, e.g. 'en'
   * @param {string} param0.title Title
   * @param {string} param0.desc Description
   * @returns
   */
  save_content: ({ contentType, thumbnailUrl, fileUrl, fileDefaultName, lang, title, desc, currentuser }) => {
    return requests.post('/training/ClientActivity/', {
      data: {
        action: 'upload',
        formdata: {
          contentType,
          contentTypeSort: contentType === 'poster' ? 'PS' : 'PL',
          thumbnailUrl,
          fileUrl,
          fileDefaultName,
          lang,
          title,
          desc,
          contentStatus: 1
        },
        currentuser
      }
    });
  },
  delete_one: ({ id, currentuser }) => {
    return requests.post('/training/ClientActivity/', {
      data: {
        action: 'archive',
        formdata: {
          contentId: id
        },
        currentuser
      }
    });
  },
  update_one: ({
    id,
    title,
    description,
    lang,
    thumbnailUrl,
    fileUrl,
    givenFileName,
    archiveTraining,
    currentuser,
    isTraningArchived
  }) => {
    return requests.post('/training/ClientActivity/', {
      data: {
        action: 'update',
        formdata: {
          contentId: id,
          isTraningArchived,
          // ...(fileUrl && { isTraningArchived: archiveTraining }),
          payload: {
            title,
            description,
            language_code: lang,
            ...(thumbnailUrl && { thumbnail_path: thumbnailUrl }),
            ...(fileUrl && { content_url: fileUrl }),
            ...(givenFileName && { given_file_name: givenFileName })
          }
        },
        currentuser
      }
    });
  }
};

export default {
  Auth,
  Clients,
  Cspa,
  Phishing,
  ClientDashboard,
  API_ROOT,
  Training,
  Support,

  setToken: _token => {
    token = _token;
  }
};
