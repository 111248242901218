import {APP_LOAD,LOGOUT,REDIRECT, CHANGE_SIDEBAR_WIDTH} from '../constants/actionTypes';
const defaultState = {
  appName: 'CyberQ',
  token: null,
  viewChangeCounter: 0,
  sidebarWidth: 0,
};

export default (state = defaultState, action) => {
  switch (action.type) {
    case CHANGE_SIDEBAR_WIDTH:
      return { ...state, sidebarWidth: action.payload };
    case APP_LOAD:
      return {
        ...state,
        token: action.token || null,
        appLoaded: true,
        currentuser: action.payload ? action.payload.user : null,
        allmodules: action.payload ? action.payload.user.allmodules:'',
        mymodules: action.payload ? action.payload.user.rolemodules:'',
        countrylist: action.payload ? action.payload.user.countrylist:'',
        industrylist: action.payload ? action.payload.user.industrylist:'',
        regionlist: action.payload ? action.payload.user.regionlist:'',
        gcp_cred: action.payload ? action.payload.user.gcp_cred:'',
        aws_cred: action.payload ? action.payload.user.aws_cred:'',
        azu_cred: action.payload ? action.payload.user.azu_cred:'',
        gcp_status: action.payload ? action.payload.user.gcp_status:'',
        aws_status: action.payload ? action.payload.user.aws_status:'',
        azure_status: action.payload ? action.payload.user.azure_status:'',
        assetdisc_status: action.payload ? action.payload.user.assetdisc_status:'',
      };
    case REDIRECT:
      return { ...state, redirectTo: null };
    case LOGOUT:
      return {state:null, redirectTo: '/logout', token: null, currentuser: null };
    default:
      return state;
  }
};
