import { SET_JIT_PAGES_LIBRARY, INVALIDATE_JIT_PAGES_LIBRARY } from '../constants/actionTypes';

const initialState = {
  jitPagesLibrary: {} // { [channel1]: { [category]: [ ...list ] }, [channel2]: { [category]: [ ...list ] }, }
};
export default (state = initialState, action) => {
  switch (action.type) {
    case SET_JIT_PAGES_LIBRARY: {
      const { channel, category, data } = action.payload;
      return {
        ...state,
        jitPagesLibrary: {
          ...state.jitPagesLibrary,
          [channel]: { ...state.jitPagesLibrary[channel], [category]: data }
        }
      };
    }
    case INVALIDATE_JIT_PAGES_LIBRARY: {
      return { ...state, jitPagesLibrary: {} };
    }

    default:
      return state;
  }
};
