import React, { createContext, useState, useEffect } from 'react';

export const GlobalFilterContext = createContext();

export const GlobalFilterProvider = ({ children }) => {
  const options = [
    { value: 'email', label: 'Email' },
    { value: 'sms', label: 'SMS' },
    { value: 'voice', label: 'Voice' }
  ];

  // Function to get the initial selected option from local storage or default to the "Email" option
  const getInitialOption = () => {
    const savedLabel = localStorage.getItem('selectedOption');
    const savedOption = options.find(option => option.label === savedLabel);
    return savedOption ? savedOption : options.find(option => option.label === 'Email');
  };

  const [selectedOption, setSelectedOption] = useState(getInitialOption);

  useEffect(() => {
    if (selectedOption) {
      localStorage.setItem('selectedOption', selectedOption.label);
    }
  }, [selectedOption]);

  return (
    <GlobalFilterContext.Provider value={{ selectedOption, setSelectedOption, options }}>
      {children}
    </GlobalFilterContext.Provider>
  );
};
