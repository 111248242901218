import 'react-app-polyfill/ie9';
import 'react-app-polyfill/stable';
import React, { useMemo, useState, useCallback } from 'react';
import ReactDOM from 'react-dom';
import App from './App';
import Main from './Main';
import './helpers/initFA';
import { Provider } from 'react-redux';
import { store } from './store';
import { ConnectedRouter } from 'connected-react-router';
import { history } from './history';
import { createTheme, ThemeProvider } from '@material-ui/core/styles';
import PubSubProvider from 'utils/usePubSub/Provider';
import { getItemFromStore } from 'helpers/utils';
import { GlobalFilterProvider } from 'components/common/GlobalFilterContext/GlobalFilterContext';

if (module.hot) {
  module.hot.accept();
}

function Rerenderer({ children, ...props }) {
  const [isDark, setIsDark] = useState(getItemFromStore('isDark', true));
  const [rootKey, setRootKey] = useState(Date.now());

  const handleDarkModeChange = useCallback(_isDark => {
    setIsDark(_isDark);
  }, []);

  const theme = useMemo(() => {
    // Yeah it's not recommended to modify the html like this, but I found it to be the most
    // straightforward and maintainable way to do this. We wanna avoid adding DarkTheme/LightTheme
    // classes in multiple Routes.
    const body = document.getElementsByTagName('BODY')[0];
    body.classList.add(isDark ? 'DarkTheme' : 'LightTheme');
    body.classList.remove(isDark ? 'LightTheme' : 'DarkTheme');

    if (isDark) {
      return createTheme({
        palette: {
          type: 'dark',
          background: {
            default: '#061325',
            paper: '#152944'
          }
        }
      });
    } else {
      return createTheme({
        palette: {
          type: 'light',
          background: {
            default: '#fff',
            paper: '#fff'
          }
        }
      });
    }
  }, [isDark]);

  return (
    <React.Fragment key={rootKey}>
      <ThemeProvider theme={theme}>
        <Provider store={store}>
          <ConnectedRouter history={history}>
            <PubSubProvider>
            <GlobalFilterProvider>
                <Main onDarkModeChange={handleDarkModeChange}>
                  <App />
                </Main>
              </GlobalFilterProvider>
            </PubSubProvider>
          </ConnectedRouter>
        </Provider>
      </ThemeProvider>
    </React.Fragment>
  );
}

ReactDOM.render(<Rerenderer />, document.getElementById('main'));
