import agent from './agent';
import {
  ASYNC_START,
  ASYNC_END,
  LOGIN,
  LOGIN_CODE_GENERATE,
  CLIENT_LOGIN,
  LOGOUT,
} from './constants/actionTypes';

const promiseMiddleware = store => next => action => {
  if (isPromise(action.payload)) {
    store.dispatch({ type: ASYNC_START, subtype: action.type });

    const currentView = store.getState().viewChangeCounter;
    const skipTracking = action.skipTracking;

    action.payload.then(
      res => {
        const currentState = store.getState()
        if (!skipTracking && currentState.viewChangeCounter !== currentView) {
          return
        }
        action.payload = res;
        store.dispatch({ type: ASYNC_END, promise: action.payload });
        store.dispatch(action);
      },
      error => {
        const currentState = store.getState()
        if (!skipTracking && currentState.viewChangeCounter !== currentView) {
          return
        }
        action.error = true;
        action.payload = error.response.body;
        if (!action.skipTracking) {
          store.dispatch({ type: ASYNC_END, promise: action.payload });
        }
        store.dispatch(action);
      }
    );

    return;
  }

  next(action);
};

const localStorageMiddleware = store => next => action => {
  if (action.type == LOGIN) {  /* eslint eqeqeq: 0 */
    if (!action.error) {
      window.sessionStorage.setItem('jwt', action.payload.user.token);
      agent.setToken(action.payload.user.token);
    }
  }else if (action.type == CLIENT_LOGIN) {  /* eslint eqeqeq: 0 */
    if (!action.error && action.payload.user != null && action.payload.user.token != null) {
      window.sessionStorage.setItem('jwt', action.payload.user.token);
      agent.setToken(action.payload.user.token);
    }
  } else if (action.type == LOGOUT) {  /* eslint eqeqeq: 0 */
    window.sessionStorage.setItem('jwt', '');
    agent.setToken(null);
  }else if (action.type == LOGIN_CODE_GENERATE) {  /* eslint eqeqeq: 0 */
    if (!action.error) {
      console.log(action.payload)
    }
  }

  next(action);
};

function isPromise(v) {
  return v && typeof v.then == 'function';  /* eslint eqeqeq: 0 */
}


export { promiseMiddleware, localStorageMiddleware }
